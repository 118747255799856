.navbar{
    box-shadow: none !important;
  }
  .navbar-brand-1{
    color: black !important;
  -webkit-text-stroke: 1px black;
  }
  .navbar-nav-1 li a{
    color: black !important;
  }
  .btn-outline-success{
  padding: 12px 40px 12px 40px !important;
  border-radius: 40px !important;
  background-color: #00E000 !important;
  color: white !important;
  border: none !important;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
  }
  .navbar-toggler-1{
    border: 1px solid black !important;
  }
  .navbar-toggler:focus{
    color: transparent !important;
  }
  @media (max-width:767px) {
    .hero-section-swiper {
      padding: 12px 20px 10px 20px;
      font-size: 16px;
  }
  }
  .nav-link {
    position: relative;
  }
  
  .nav-link.active1::after {
    content: '';
    position: absolute;
    bottom: -2px; 
    left: 50%;
    transform: translateX(-50%);
    width: 40%;
    height: 3px;
    border-radius: 5px;
    background-color: black;
  }
  .navbar-projects{
    box-shadow: 0px 4px 20px 0px #0000001A !important;
  }
  .project-s1-h1{
    font-family: Montserrat;
font-size: 60px;
font-weight: 700;
line-height: 73.14px;
-webkit-text-stroke: 1px black;
  }
  .project-s1-text1{
    font-family: Montserrat;
font-size: 24px;
font-weight: 500;
line-height: 29.26px;
  }
  .project-s1-text2{
    font-family: Montserrat;
font-size: 24px;
font-weight: 500;
line-height: 29.26px;
  }
  .project-s1-btn1{
padding: 15px 40px 15px 40px;
border-radius: 10px;
background-color: #444444;
color: white;
border: none !important;
  }
  .project-s1-btn2{
    padding: 15px 40px 15px 40px;
    border-radius: 10px;
    color: white;
    border: none;
    background-color: #61DD00;
      }
      @media (max-width:1399px) {
        .project-s1-h1 {
            font-size: 40px;
            line-height: 42.14px;
        }
        .project-s1-text2 {
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 500;
            line-height: 29.26px;
        }
        .project-s1-text1 {
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 500;
            line-height: 29.26px;
        }
      }
      @media (max-width:767px) {
        .project-s1-h1 {
            font-size: 27px;
            line-height: 32.14px;
        }
        .project-s1-text2 {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 500;
            line-height: 29.26px;
        }
        .project-s1-text1 {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 500;
            line-height: 29.26px;
        }
      }
      @media (max-width:447px) {
        .project-s1-btn2 {
            padding: 10px 15px 10px 15px;
        }
        .project-s1-btn1 {
            padding: 10px 15px 10px 15px;
        }
      }
      .img-project-shdw{
        border-radius: 25px
      }
      .project-s2-text{
        font-family: Montserrat;
font-size: 22px;
font-weight: 400;
line-height: 36px;
      }
      @media (max-width:767px) {
        .project-s2-text{
            font-family: Montserrat;
    font-size: 18px;
    line-height: 28px;
          }
      }