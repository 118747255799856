.footer1-heading{
    font-family: Montserrat;
font-size: 48px;
font-weight: 800;
line-height: 58.51px;
color: #002DCC;
-webkit-text-stroke: 1px #002DCC;
}
.footer1-text{
    font-family: Montserrat;
font-size: 20px;
font-weight: 500;
line-height: 24.38px;
color: #002DCC;
}
.footer1-btn{
padding: 15px 45px;
border-radius: 10px;
border: none;
background-color:#61DD00;
color: black;
text-decoration: none;
}
    .footer1-position1{
        height: 464px !important;
        position: absolute;
        right: 0 !important;
        bottom: 0 !important;
    }   
.footer1-position2{
    position: relative;
}
.footer1_main{
    position: relative;
}
.footer1-position3{
    position: absolute;
    right: 0%;
    top: 0%;
}
.footer1-position4{
    position: absolute;
    bottom: 0%;
    left: 0%;
}
.custom-padding-footer1{
    padding-left: 3rem;
    padding-right: 3rem;
}
@media (max-width:1199px) {
    .custom-padding-footer1{
        padding-left: 0rem;
        padding-right: 0rem;
    }
}
@media (max-width:1130px) {
    .custom-padding-footer1{
        padding-left: 4rem;
    }
}
@media (max-width:991px) {
    .footer1-position3{
    }
}
@media (max-width:991px) {
    .footer1-position4{
        left: -5%;
    }
}
@media (max-width:780px) {
    .footer1-position4{
        left: -7%;
    }
}
@media (max-width:991px) {
    .footer1-position1{
        top: unset !important;
        bottom: 0% !important;
    }
}
@media (max-width:767px) {
    .footer1-position1{
        position: unset;
    }
    .custom-padding-footer1{
        padding-left: 0rem !important;
    }   
}
@media (max-width:767px) {
    .footer1-heading{
        font-size: 27px;
        line-height: 37px;
    }
}
@media (max-width:767px) {
    .footer1-position3{
        top: 38%;
    }
}
@media (max-width:500px) {
    .footer1-position3{
        display: none;
    }
    .footer1-position4{
        display: none;
    }
}
@media (max-width:550px) {
    .footer1-position1{
        height: 300px;
    }
}
@media (max-width:500px) {
    .footer1-position1{
        height: auto !important;
    }
}
.footer2-bg{
    background: linear-gradient(180deg, #002DCC 0%, #001F8E 100%);
}
.footer2-bg-img{
border-radius: 15px;
background-color: #FFFFFF;
padding: 30px;
}
.footer2-text1{
    font-family: Montserrat;
font-size: 18px;
font-weight: 500;
color: #FFFFFF;
}
.footer2-text2{
    font-family: Montserrat;
font-size: 18px;
font-weight: 700;
color: #FFFFFF;
}
.footer2-heading1{
    font-family: Montserrat;
font-size: 40px;
font-weight: 800;
color: #FFFFFF;
-webkit-text-stroke: 1px white;
}
.footer2-text3{
    font-family: Montserrat;
font-size: 18px;
font-weight: 500;
line-height: 22.38px;
color: #FFFFFF;
}
.footer2-btn1{
padding: 10px 30px;
gap: 10px;
border-radius: 10px;
border: 2px solid #FFFFFF;
background-color:transparent;
color: #FFFFFF;
width: 150px;
}
.footer2-btn2{
    padding: 10px 30px;
    gap: 10px;
    border-radius: 10px;
    border: 2px solid #61DD00;
    background-color:#61DD00;
    color: #FFFFFF;
    width: 150px;
    }
    @media (max-width:991px) {
        .footer2-btn1{
            width: 117px;
            padding: 10px 13px;
        }
        .footer2-btn2{
            width: 117px;
            padding: 10px 13px;
        }
    }
@media(max-width:767px) {
    .footer2-bg-img {
        border-radius: 15px;
        background-color: #FFFFFF;
        padding: 20px;
        height: 60px !important;
        width: 60px !important;
    }
}
@media (max-width: 369px) {
    .footer2-btn2 {
        width: 109px;
        padding: 10px 10px;
    }
    .footer2-btn1 {
        width: 109px;
        padding: 10px 10px;
    }
}
@media (max-width: 767px) {
    .footer2-heading1 {
        font-size: 27px;
        line-height: 37px;
    }
    .footer2-text1 {
        font-size: 16px;
    }
    .footer2-text2 {
        font-size: 16px;
    }
}
@media (max-width:425px) {
    .footer2-text1 {
        font-size: 13px;
    }
    .footer2-text2 {
        font-size: 13px;
    }
}
.footer3-bg{
    background-color:#383838;
}
.footer3-heading{
    font-family: Montserrat;
font-size: 36px;
font-weight: 700;
line-height: 43.88px;
color: #FFFFFF;
}
.footer3-text{
    font-family: Montserrat;
font-size: 18px;
font-weight: 600;
line-height: 28.8px;
color: #FFFFFF;
}
.footer3-bg .footer .nav .nav-item a{
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 28.8px;
    color: #FFFFFF !important;    
}
.footer-copyright-wrapper p{
    font-family: Montserrat;
font-size: 14px;
font-weight: 400;
line-height: 22.4px;
text-align: center;
color: #FFFFFF;
}
.footer3-mainheading{
    font-family: Montserrat;
font-size: 50px;
font-weight: 700;
line-height: 60.95px;
text-align: left;
}
.footer3-text{
    width: 40%;
}
@media (max-width:991px) {
    .footer3-text{
        width: 75%;
    }
}
@media (max-width:767px) {
    .footer3-heading{
        font-size: 27px;
    }
    .footer3-text{
        font-size: 16px;
        line-height: 24px;
    }
    .footer3-bg .footer .nav .nav-item a {
        font-size: 16px;
    }
}