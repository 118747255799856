.hero-section-bg-pg2{
    background: linear-gradient(180deg, #002ED4 0%, #002ED4 0.01%, #002094 100%) !important;
position: relative;
}
.custom-img-position-heroSection{
    position: absolute;
    top: 2%;
    left: 2%;
    height: 125px;
  }
  .custom-img2-position-heroSection{
    position: absolute;
    bottom: 8%;
    right: 2%;
    height: 125px;
  }
  @media screen and (max-width:767px) {
    .custom-img-position-heroSection{
      display: none;
    }
    .custom-img2-position-heroSection{
      display: none;
    }
  }
  .hero-section-position{
    position: relative;
  }
  .hero-img1-position{
    position: absolute;
    left: 0;
  }
  .hero-img2-position{
    position: absolute;
    right: -2%;
    top: -10%;
  }
  .hero-img3-position{
    position: absolute;
    left: -7%;
    bottom: 11%;
  }
  .hero-img4-position{
    position: absolute;
    right: -1%;
    bottom: -8%;
  }
  @media screen and (max-width:991px) {
    .hero-img3-position{
      bottom: 32%;
    }
    .hero-img4-position{
      bottom: 11%;
    }
  }
  @media screen and (max-width:620px) {
    .hero-img3-position{
      left: 0%;
    }
    .hero-img4-position{
      right: 0%;
    }
    .hero-img2-position{
      right: 0%;
    }
    .hero-img3-position{
      left: 0%;
    }
  }
  @media screen and (max-width:580px) {
    .hero-img3-position{
      bottom: 16%;
    }
    .hero-img4-position{
      bottom: -6%;
    }
  }
  .hero-s1-rsum-h{
    font-family: Montserrat;
font-size: 54px;
font-weight: 800;
line-height: 65.83px;
color: #FFFFFF;
-webkit-text-stroke: 1px white;
  }
  .hero-s1-rsum-p{
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    line-height: 26.82px;
    color: #FFFFFF;
  }
  @media (max-width:767px) {
    .hero-s1-rsum-h{
      font-size: 30px;
    }
    .hero-s1-rsum-p{
      font-size: 17px;
    }
  }
  .hero-section1-btn1{
    font-family: Montserrat;
padding: 10px 40px 10px 40px;
gap: 10px;
border-radius: 10px;
color: black;
background-color:#61DD00;
border: none;
font-weight: 800;
  }
  .hero-section1-btn2{
    background-color: transparent;
    color: #61DD00;
    border: none;
    font-weight: 800;
    font-family: Montserrat;
  }
  .pg2-hero-img1-position{
    position: absolute;
    top: -16%;
    left: 10%;
  }
  .pg2-hero-img3-position {
    position: absolute;
    left: 15%;
    bottom: -34%;
}
.pg2-hero-img2-position {
  position: absolute;
  right: 8%;
  top: -39%;
}
.pg2-hero-img4-position {
  position: absolute;
  right: -1%;
  bottom: -59%;
}
@media (max-width:767px) {
  .pg2-hero-img1-position {
    position: absolute;
    top: -16%;
    left: -3%;
}
.pg2-hero-img3-position {
  position: absolute;
  left: -3%;
  bottom: -34%;
}
}
@media (max-width:600px) {
  .pg2-hero-img4-position {
    right: 0%;
    bottom: -52%;
}
}
@media (max-width:500px) {
  .custom-padding-hero-pg2{
    margin-top: 30px !important;
  }
  .pg2-hero-img4-position {
    bottom: -60%;
}
.pg2-hero-img1-position {
  top: -32%;
  left: 0%;
}
.pg2-hero-img2-position {
  top: -50%;
}
.pg2-hero-img3-position {
  left: 0%;
}
}
@media (max-width:380px) {
  .pg2-hero-img1-position {
    top: -14%;
    left: -3%;
}
.pg2-hero-img2-position {
  top: -35%;
}
.pg2-hero-img4-position {
  bottom: -43%;
}
.pg2-hero-img3-position {
  bottom: -21%;
}
}
@media (max-width:380px) {
  .buttons-direction-pg2-hero{
    flex-direction: column;
  }
  .hero-section1-btn2{
    margin-top: 10px !important;
  }
}
.btn-outline-success1 {
  padding: 12px 40px 12px 40px !important;
  border-radius: 10px;
  background-color:#444444 !important; 
  color: white !important;
  border: none !important;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}
.hero-pg2-position11{
  position: relative;
}
.hero-section-swiper2{
  position: absolute;
  right: 0%;
  bottom: 10%;
}
@media (max-width:767px) {
  .hero-section-swiper2{
    bottom: 5%;
  }
}
.pg2-s1-title{
  font-family: Montserrat;
font-size: 18px;
font-weight: 600;
line-height: 21.94px;
letter-spacing: 0.4em;
color: #002DCC;
}
.pg2-s1-heading{
  font-family: Montserrat;
font-size: 48px;
font-weight: 800;
line-height: 58.51px;
color: black;
-webkit-text-stroke: 1px black;

}
.pg2-s1-text1{
  font-family: Montserrat;
font-size: 20px;
font-weight: 400;
line-height: 24.38px;
color: black;
}
@media (max-width:767px) {
  .pg2-s1-heading{
  font-size: 27px;
  font-weight: 800;
  line-height: 37.51px;
  }
  .pg2-s1-text1{
    font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.38px;
  color: black;
  }
}
.pg2-s1-text2{
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 800;
  line-height: 21.94px;  
}
.s1-position-img1-pg2{
  position: relative;
}
.s1-position-img2-pg2{
  position: absolute;
  bottom: 0%;
}
.main-s2-position-pg2{
  position: relative;
}
.hero-section-swiper1{
  position: absolute;
  right: 0%;
  bottom: 0%;
  padding: 12px 34px 12px 35px;
  border-radius: 30px;
  background-color: #F8F8F8 !important;
  border: none;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.hero-section-swiper2{
  padding: 12px 34px 12px 35px;
  border-radius: 30px;
  background-color: #F8F8F8 !important;
  border: none;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 4px 4px 0px #00000040;
  }
  @media (max-width:991px) {
    .s1-position-img2-pg2{
      position: unset;
    }
  }
  .horizontal-line{
    background-color:#444444;
    height: 1px;
  }
  #conference-timeline {
    position: relative;
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
  }
  #conference-timeline .timeline-start,
  #conference-timeline .timeline-end {
    display: table;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    background: #00b0bd;
    padding: 15px 23px;
    color: #fff;
    max-width: 5%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  #conference-timeline .conference-center-line {
    position: absolute;
    width: 3px;
    height: 98%;
    top: 15px;
    left: 50%;
    margin-left: -2px;
    background: #444444;
    z-index: -1;
  }
  .timeline-article {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    margin: 20px 0;
  }
  .timeline-article .content-left-container,
  .timeline-article .content-right-container {
    max-width: 44%;
    width: 100%;
  }
  .timeline-article .timeline-author {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #242424;
    text-align: right;
  }
  .timeline-article .content-left,
  .timeline-article .content-right {
    position: relative;
    width: auto;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  .timeline-article p {
    padding: 0;
    font-weight: 400;
    color: #242424;
    font-size: 14px;
    line-height: 24px;
    position: relative;
  }
  .timeline-article p span.article-number {
    position: absolute;
    font-weight: 300;
    font-size: 44px;
    top: 10px;
    left: -60px;
    color: #444444;
  }
  .timeline-article .content-left-container {
    float: left;
  }
  .timeline-article .content-right-container {
    float: right;
  }
  .timeline-article .meta-date {
    position: absolute;
    top: 43%;
    left: 50%;
    width: 62px;
    height: 62px;
    margin-left: -31px;
    color: #fff;
    border-radius: 100%;
    background: #444444;
  }
  .timeline-article .meta-date .date,
  .timeline-article .meta-date .month {
    display: block;
    text-align: center;
    font-weight: 900;
  }
  .timeline-article .meta-date .date {
    font-size: 30px;
    line-height: 40px;
  }
  .timeline-article .meta-date .month {
    font-size: 18px;
    line-height: 10px;
  }
  .content1-s2{
padding: 22px 20px 22px 20px !important;
border-radius: 10px !important;
background-color: #444444 !important;
box-shadow: 4px 4px 20px 2px #0000001A !important;
border: none !important;
margin-left: 15px;
margin-top: 15px;
margin-bottom: 15px;
  }
  .content2-s2{
    border: none !important;
    margin-top: 31%;
  }
  .content2-s2 p{
    border: none !important;
    margin: 0px !important;
  }
  .content1-s2 ul li{
    font-family: Montserrat !important;
font-size: 20px !important;
font-weight: 500 !important;
line-height: 32px !important;
color: white !important;
  }

  .s2-content2-heading-pg2{
    font-family: Montserrat;
    font-size: 40px !important;
    font-weight: 700 !important;
    line-height: 48.76px !important;
    color: #444444 !important;  
  }
  .s2-content2-heading1-pg2{
    font-family: Montserrat;
font-size: 32px !important;
font-weight: 500 !important;
line-height: 39.01px !important;
color: #444444 !important;  
  }
  .s2-content2-text1-pg2{
    font-family: Montserrat;
font-size: 24px !important;
font-weight: 500 !important;
line-height: 29.26px !important;
color: #444444 !important;  
  }
  .s2-content2-text2-pg2{
    font-family: Montserrat;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 24.38px !important;  
    color: #444444 !important;  
  }
  .content3-s2{
    border: none !important;
    margin-top: 42% !important;
  }
  .content3-s3{
    padding: 22px 20px 22px 20px !important;
border-radius: 10px !important;
color: #444444 !important;
box-shadow: 4px 4px 20px 2px #0000001A !important;
margin-top: 15px;
margin-bottom: 15px;
margin-right: 15px;
border: none !important;
  }
  .content4-s3{
    padding: 22px 20px 22px 20px !important;
border-radius: 10px !important;
box-shadow: 4px 4px 20px 2px #0000001A !important;
border: none !important;
margin-top: 15px;
margin-bottom: 15px;
margin-left: 15px;
  }
  .content4-s3 ul li{
    font-family: Montserrat !important;
font-size: 20px !important;
font-weight: 500 !important;
line-height: 32px !important;
color: #444444 !important;
  }
  .content6-s3{
    border: none !important;
    margin-top: 20%;
  }
  @media (max-width:991px) {
    #conference-timeline{
display: none;
    } 
  }
  .meta-date1 {
    width: 62px;
    height: 62px;
    color: #fff;
    border-radius: 100%;
    background: #444444;
  }
.meta-date1 .date,
 .meta-date1 .month {
    display: block;
    text-align: center;
    font-weight: 900;
  }
 .meta-date1 .date {
    font-size: 30px;
    line-height: 40px;
  }
.meta-date1 .month {
    font-size: 18px;
    line-height: 10px;
  }
  .content1-s7 {
    padding: 22px 20px 22px 20px !important;
    border-radius: 10px !important;
    background-color: #444444 !important;
    box-shadow: 4px 4px 20px 2px #0000001A !important;
    border: none !important;
}
.content1-s7 ul li{
  font-family: Montserrat !important;
font-size: 20px !important;
font-weight: 500 !important;
line-height: 32px !important;
color: white !important;
}
.content2-s7{
  padding: 22px 20px 22px 20px !important;
border-radius: 10px !important;
color: #444444 !important;
box-shadow: 4px 4px 20px 2px #0000001A !important;
border: none !important;
}
.content2-s7 ul li{
  font-family: Montserrat !important;
font-size: 20px !important;
font-weight: 500 !important;
line-height: 32px !important;
color: #444444 !important;
}
@media (max-width:767px) {
  .s2-content2-heading-pg2{
    font-size: 27px !important;
    line-height: 30px !important;
  }
  .s2-content2-heading1-pg2 {
    font-family: Montserrat;
    font-size: 25px !important;
    line-height: 30.01px !important;
}
.s2-content2-text2-pg2 {
    font-size: 16px !important;
}
.content1-s7 ul li{
  font-size: 16px !important;
}
.content2-s7 ul li{
  font-size: 16px !important;
}
}
@media (min-width:991px) {
  .conference-timeline2{
    display: none;
  }
}
.hero-section-swiper3{
  position: absolute;
  right: 0%;
  bottom: 0%;
}
.hero-section-swiper3{
  padding: 12px 34px 12px 35px;
  border-radius: 30px;
  background-color: #F8F8F8 !important;
  border: none;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 4px 4px 0px #00000040;
  }
  .conference-timeline2{
    position: relative;
  }
  .hero-section-swiper4{
    position: absolute;
    right: 0%;
    bottom: 0%;
  }
  .hero-section-swiper4{
    padding: 12px 34px 12px 35px;
    border-radius: 30px;
    background-color: #F8F8F8 !important;
    border: none;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0px 4px 4px 0px #00000040;
    }
    @media (max-width:767px) {
      .hero-section-swiper4 {
        padding: 12px 20px 10px 20px;
        font-size: 16px;
    }
    .hero-section-swiper3 {
      padding: 12px 20px 10px 20px;
      font-size: 16px;
  }
  .hero-section-swiper2 {
    padding: 12px 20px 10px 20px;
    font-size: 16px;
}
.hero-section-swiper1 {
  padding: 12px 20px 10px 20px;
  font-size: 16px;
}
    }

    .timeline {
      position: relative;
      width: 100%;
      max-width: 1140px;
      margin: 0 auto;
      padding: 15px 0;
    }
    
    .timeline::after {
      content: '';
      position: absolute;
      width: 2px;
      background-color: white;
      top: 60px;
      bottom: 55px;
      left: 50%;
      margin-left: -1px;
    }
    
    .container1 {
      padding: 15px 30px;
      position: relative;
      background: inherit;
      width: 50%;
    }
    
    .container1.left {
      left: 0;
    }
    
    .container1.right {
      left: 50%;
    }

    .container1.right::after {
      left: -8px;
    }
    
    .container1::before {
      content: '';
      position: absolute;
      width: 58px;
      height: 2px;
      top: calc(50% - 1px);
      right: 0px;
      background: white;
      z-index: 1;
    }
    
    .container1.right::before {
      left: 0px;
    }
    
    .container1 .date {
      position: absolute;
      display: inline-block;
      top: calc(50% - 8px);
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #006E51;
      text-transform: uppercase;
      letter-spacing: 1px;
      z-index: 1;
    }
    
    .container1.left .date {
      right: -75px;
    }
    
    .container1.right .date {
      left: -75px;
    }
    
    
    .container1.right .icon {
      left: 35px;
      background-color: white;
      border: 1px solid white !important;
      color: #444444 !important;
    }
    
    .container1 .content {
      padding: 30px 90px 30px 30px;
      position: relative;
    }
    
    .container1.right .content {
      padding: 30px 30px 30px 90px;
      border-radius: 500px 0 0 500px;
    }
    
    .container1 .content h2 {
      margin: 0 0 10px 0;
      font-size: 18px;
      font-weight: normal;
      color: #006E51;
    }
    
    .container1 .content p {
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
    
    @media (max-width: 767.98px) {
      .timeline::after {
        left: 90px;
      }
    
      .container1 {
        width: 100%;
        padding-left: 120px;
        padding-right: 30px;
      }
    
      .container1.right {
        left: 0%;
      }
    
      .container1.left::after, 
      .container1.right::after {
        left: 82px;
      }
    
      .container1.left::before,
      .container1.right::before {
        left: 100px;
        border-color: transparent #006E51 transparent transparent;
      }
    
      .container1.left .date,
      .container1.right .date {
        right: auto;
        left: 15px;
      }
    
      .container1.left .icon,
      .container1.right .icon {
        right: auto;
        left: 146px;
        color: #444444 !important;
        font-size: 30px !important;
      }
      .container1.left .content,
      .container1.right .content {
        padding: 30px 30px 30px 90px;
        border-radius: 500px 0 0 500px;
      }
    }
    .container1.left .icon {
      right: 35px;
      background-color: white;
      border: 1px solid white !important;
      color: #444444 !important;
    }
    .container1 .icon {
      font-weight: bold;
      position: absolute;
      display: inline-block;
      width: 62px;
      height: 62px;
      padding: 9px 0;
      top: calc(50% - 31px);
      border: 2px solid black;
      border-radius: 40px;
      text-align: center;
      font-size: 29px;
      color: black;
      z-index: 1;
    }
    .pg2-section4-bg {
      background: url('/public/assets/pg2-s4img.png'), linear-gradient(180deg, #002ED4 0%, #002ED4 0.01%, #002094 100%);
      background-size: cover; 
      background-repeat: no-repeat;
      background-position: center;
  }
  .s4-h-pg2{
    -webkit-text-stroke: 1px white!important;
  }
  .timeline2-bg{
    background-color: white !important;
padding: 25px 25px 25px 25px;
border-radius: 10px;
box-shadow: 4px 4px 20px 2px #00000033;

  }
  .timeline2-heading{
    font-family: Montserrat !important;
font-size: 29px !important;
font-weight: 700 !important;
line-height: 40.76px !important;
color: #444444 !important;
  }
  .timeline2-heading2{
    font-family: Montserrat;
font-size: 22px;
font-weight: 500;
color: #444444 !important;
  }
  .timeline2-text{
    font-family: Montserrat;
font-size: 20px;
font-weight: 500;
color: #444444 !important;
  }
  .timeline2-heading3{
    font-family: Montserrat;
font-size: 24px;
font-weight: 700;
color: #444444 !important;
  }
  .timeline2-bg ul li{
    font-family: Montserrat;
font-size: 18px;
font-weight: 500;
color: #444444 !important;
  }
  @media (max-width:991px) {
    .timeline{
      display: none;
    }    
  }
@media (min-width:991px) {
  .timeline3{
    display: none;
  }
}
.meta-date2 {
  width: 62px;
  height: 62px;
  color: #444444;
  border-radius: 100%;
  background: white;
}
.meta-date2 .date,
.meta-date2 .month {
  display: block;
  text-align: center;
  font-weight: 900;
}
.meta-date2 .date {
  font-size: 30px;
  line-height: 40px;
}
.meta-date2 .month {
  font-size: 18px;
  line-height: 10px;
}
@media (max-width:767px) {
  
.timeline2-heading {
  font-family: Montserrat !important;
  font-size: 27px !important;
  font-weight: 700 !important;
  line-height: 30.76px !important;
}

.timeline2-heading3 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  color: #444444 !important;
}
.timeline2-bg ul li {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: #444444 !important;
}

.timeline2-heading2 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  color: #444444 !important;
}
.timeline2-text {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  color: #444444 !important;
}
}
.pg1-s8-bg12{
    background: linear-gradient(220.74deg, #FFFFFF 6.8%, #B2B0FF 97.83%);
}
.pg1-s8-bg12 .span1 {
  font-size: 16px;
  font-weight: 600;
  border-radius: 100%;
  background-color: #002DCC;
  color: white;
  border: 2px solid white;
  padding: 12px 20px;
}
.pg1-s8-bg12 .span2 {
  font-size: 16px;
  font-weight: 600;
  border-radius: 100%;
  background-color: transparent;
  color: #002DCC;
  border: 2px solid #002DCC;
  padding: 12px 20px;
}