.hero-section1-btn22{
padding: 10px 40px 10px 40px;
border-radius: 10px;
background-color: white;
color: #444444;
border: none !important;
font-weight: 800;
font-family: Montserrat;
}
.main-image{
    padding-left: 9%;
    padding-right: 9%;
}
.about-padding3{
    margin-top: 8%;
}
.about-section-padding1{
    margin-top: -141%;
    padding-left: 11%;
    padding-right: 11%;
}
.about-section-padding2 {
    margin-top: -62%;
    padding-left: 11%;
    padding-right: 11%;
}
.about-section-position3{
    position: absolute;
    top: 0%;
    left: 36%;
    z-index: -1;
}
.about-section-position1{
    position: relative;
}
.linkedin-logo {
    position: absolute;
    top: -40%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 10px;
    border-radius: 5px;
    z-index: 0;
  }
  .profile-top-left {
    top: -64px;
    left: 40px;
}
  
.profile-bottom-right {
    bottom: -64px;
    right: 30px;
}
@media (max-width:1199px) {
    .profile-bottom-right {
        bottom: -48px;
        right: 30px;
    }
    .profile-top-left {
        top: -48px;
        left: 40px;
    }
}
  @media (max-width:991px) {
    .profile-top-left img,.profile-bottom-right img{
        height: 75px;
    }
        .profile-bottom-right {
            bottom: -27px;
            right: 33px;
        }
        .profile-top-left {
            top: -27px;
            left: 38px;
        }
  }
  @media (max-width:767px) {
    .profile-top-left img,.profile-bottom-right img{
        height: 50px;
    }
    .profile-bottom-right {
        bottom:-20px;
        right: 25px;
    }
    .profile-top-left {
        top: -20px;
        left: 25px;
    }
  }
  @media (max-width:500px) {
    .profile-top-left img,.profile-bottom-right img{
        height: 35px;
    }
    .profile-bottom-right {
        bottom: -18px;
        right: 24px;
    }
    .profile-top-left {
        top: -18px;
        left: 25px;
    }
  }
  @media (max-width: 450px) {
    .profile-bottom-right {
        bottom: -20px;
        right: 17px;
    }
    .profile-top-left {
        top: -20px;
        left: 17px;
    }
}
@media (max-width: 450px) {
    .profile-bottom-right {
        bottom: -15px;
        right: 17px;
    }
    .profile-top-left {
        top: -15px;
        left: 17px;
    }
}
  .about-section-1{
    margin-top: 10%;
  }
  .about-section-1-part-1{
    margin-top: -82%;
  }
      .linkedin-logo {
        top: -36%;
    }
  @media (max-width:1900px) {
    .linkedin-logo {
        top: -36%;
    }
  }
  @media (max-width: 1399px) {
    .linkedin-logo {
        top: -40%;
    }
}
@media (max-width: 1199px) {
    .linkedin-logo {
        top: -47%;
    }
}
@media (max-width: 991px) {
    .linkedin-logo {
        top: -50%;
    }
}
@media (max-width:767px) {
        .linkedin-logo {
            top: -50%;
        }
}
@media (max-width:500px) {
    .linkedin-logo {
        top: -52%;
    }
}
@media (max-width:1900px) {
    .main-image{
        margin-top: 8rem !important;
    }
}
@media (max-width:1800px) {
    .main-image{
        margin-top: 7rem !important;
    }
}
@media (max-width:1700px) {
    .main-image{
        margin-top: 6rem !important;
    }
}
@media (max-width:1600px) {
    .main-image{
        margin-top: 5rem !important;
    }
}
@media (max-width:1500px) {
    .main-image{
        margin-top: 4rem !important;
    }
}
@media (max-width:1400px) {
    .main-image{
        margin-top: 3rem !important;
    }
}
.uuid-img{
    top: -9%;
    left: 1%;
}
@media (max-width:767px) {

    .uuid-img img{
        height: 50px;
    }    
    .uuid-img{
        top: 2%;
    }
}
.uuid-img3{
    right: 2%;
    top: 52%;
}
.uuid-img4{
    right: 2%;
    top: 52%;
}
@media (max-width:800px) {

    .uuid-img4 img{
        height: 50px;
    
    }    
    .uuid-img4{
        right: 0;
    }
}
@media (max-width:767px) {

    .uuid-img4 img{
        height: 50px;
    
    }    
}
@media (max-width: 500px) {
    .uuid-img4 {
        top: 0;
    }
}
@media (max-width:767px) {

    .uuid-img3 img{
        height: 50px;
    
    }    
}
@media (max-width:500px) {

    .uuid-img3{
        right: 4%;
        top: 57%;
    }    
}
.uuid-img23 {
    left: 9%;
    bottom: -49%;
}
@media (max-width:1399px) {
    .uuid-img23 {
        left: 9%;
        bottom: -42%;
    }
    .uuid-img23 img{
        height: 150px;
    }
}
@media (max-width: 991px) {
    .uuid-img23 {
        left: 9%;
        bottom: -53%;
    }
}
@media (max-width: 767px) {
    .uuid-img23 {
        left: 9%;
        bottom: -60%;
    }
    .uuid-img23 img{
        height: 110px;
    }
}
@media (max-width: 599px) {
    .uuid-img23 {
        left: 9%;
        bottom: -47%;
    }
    .uuid-img23 img{
        height: 60px;
    }
}
.about-section-1-part-2{
    margin-top: 19%;
}
@media (max-width:1199px) {
    .about-section-1-part-2 {
        margin-top: 21%;
    }
}
@media (max-width:767px) {
    .about-section-1-part-2 {
        margin-top: 22%;
    }
}
.about-section-1-part-3 {
    margin-top: -77%;
}
@media (max-width:991px) {
    .about-section-1-part-3 {
        margin-top: -81%;
    }
}
@media (max-width: 767px) {
    .about-section-1-part-3 {
        margin-top: -83%;
    }
}
@media (max-width: 620px) {
    .about-section-1-part-3 {
        margin-top: -85%;
    }
}
@media (max-width: 540px) {
    .about-section-1-part-3 {
        margin-top: -89%;
    }
}
@media (max-width: 400px) {
    .about-section-1-part-3 {
        margin-top: -94%;
    }
}
@media (max-width: 375px) {
    .about-section-1-part-3 {
        margin-top: -96%;
    }
}
.footer1-about{
    padding-top: 40%;
}
.footer1-position3-about{
    top: 32% !important;
}
@media (max-width:1800px) {
    .footer1-position3-about{
        top: 29% !important;
    }
}
@media (max-width:1750px) {
    .footer1-position3-about{
        top: 28% !important;
    }
}
@media (max-width:1700px) {
    .footer1-position3-about{
        top: 27% !important;
    }
}
@media (max-width:1670px) {
    .footer1-position3-about{
        top: 27% !important;
    }
}
@media (max-width:1650px) {
    .footer1-position3-about{
        top: 26% !important;
    }
}
@media (max-width:1600px) {
    .footer1-position3-about{
        top: 25% !important;
    }
}
@media (max-width:1570px) {
    .footer1-position3-about{
        top: 24% !important;
    }
}
@media (max-width:1540px) {
    .footer1-position3-about{
        top: 23% !important;
    }
}
@media (max-width:1500px) {
    .footer1-position3-about{
        top: 22% !important;
    }
}
@media (max-width:1450px) {
    .footer1-position3-about{
        top: 21% !important;
    }
}
@media (max-width:1399px) {
    .footer1-position3-about{
        top: 25% !important;
    }
}
@media (max-width:1350px) {
    .footer1-position3-about{
        top: 23% !important;
    }
}
@media (max-width:767px) {
    .footer1-position3-about{
        display: none;
    }
}
.footer1-position1-about{
    height: 650px !important;
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important;
}
@media (max-width:1900px) {
    .footer1-position1-about{
        height: 650px !important;
    }
}
@media (max-width:1399px) {
    .footer1-position1-about{
        height: 550px !important;
    }
}
@media (max-width: 991px) {
    .footer1-position1-about {
        height: 464px !important;
    }
}
@media (max-width:767px) {
    .footer1-position1-about {
        position: unset !important;
    }
}
.gif-border{
    border-radius: 19px;
}