
.hero-section-bg{
    background: linear-gradient(180deg, #002ED4 0%, #002ED4 0.01%, #002094 100%) !important;
  position: relative;
  }
  .custom-img-position-heroSection{
    position: absolute;
    top: 2%;
    left: 2%;
    height: 125px;
  }
  .custom-img2-position-heroSection{
    position: absolute;
    bottom: 8%;
    right: 2%;
    height: 125px;
  }
  @media screen and (max-width:767px) {
    .custom-img-position-heroSection{
      display: none;
    }
    .custom-img2-position-heroSection{
      display: none;
    }
  }
  .hero-section-heading{
    font-family: Montserrat;
  font-size: 40px;
  font-weight: bold !important;
  line-height: 58.51px;
  color: white;
  -webkit-text-stroke: 1px white;
  }
  .hero-section-text{
    font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  color: white;
  
  }
  
  .hero-section-btn1{
  padding: 10px 40px 10px 40px;
  border-radius: 40px;
  background-color: #00E000;
  color: black;
  border: none;
  font-family: Montserrat;
  font-weight: 800;
  }
  .hero-section-btn2{
    color: #00E000;
    background-color: transparent;
    border: none;
    font-family: Montserrat;
  font-weight: 800;
  }
  .hero-section-position{
    position: relative;
  }
  .hero-img1-position{
    position: absolute;
    left: 0;
  }
  .hero-img2-position{
    position: absolute;
    right: -2%;
    top: -10%;
  }
  .hero-img3-position{
    position: absolute;
    left: -7%;
    bottom: 11%;
  }
  .hero-img4-position{
    position: absolute;
    right: -1%;
    bottom: -8%;
  }
  @media screen and (max-width:991px) {
    .hero-img3-position{
      bottom: 32%;
    }
    .hero-img4-position{
      bottom: 11%;
    }
  }
  @media screen and (max-width:620px) {
    .hero-img3-position{
      left: 0%;
    }
    .hero-img4-position{
      right: 0%;
    }
    .hero-img2-position{
      right: 0%;
    }
    .hero-img3-position{
      left: 0%;
    }
  }
  @media screen and (max-width:580px) {
    .hero-img3-position{
      bottom: 16%;
    }
    .hero-img4-position{
      bottom: -6%;
    }
  }
  .hero-section-swiper{
  padding: 12px 34px 12px 35px;
  border-radius: 30px;
  background-color: #F8F8F8 !important;
  border: none;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 4px 4px 0px #00000040;
  }
  .pg1-card{
    background-color: #F8F8F8;
    box-shadow: 0px 5px 8px 0px #00000026;
    width: Fixed (350px)px;
  height: Fixed (285.83px)px;
  padding: 5.83px;
  border-radius: 11.67px;
  }
  @media screen and (max-width:499px) {
    .pg1-card{
      width: 280px;
    }
  }
  .pg1-slider-1 .swiper-wrapper{
    height: auto;
  }
  .pg1-slider-1 .swiper{
    height: 390px !important;
  }
  .pg1-slider-1 .swiper-button-prev{
  top: 85% !important;
  left: 45% !important;
  background-color: #F8F8F8 !important;
  border-radius: 100% !important;
  opacity: 1 !important;
  height: 28px !important ;
  padding: 20px !important;
  }
  .pg1-slider-1 .swiper-button-next{
    top: 85% !important;
    right: 45% !important;
    background-color: #F8F8F8 !important;
  border-radius: 100% !important;
  opacity: 1 !important;
  height: 28px !important ;
  padding: 20px !important;
  }
  @media (max-width:991px) {
    .pg1-slider-1 .swiper-button-next{
      right: 43% !important;
    }
    .pg1-slider-1 .swiper-button-prev{
      left: 43% !important;
    }
  }
  @media (max-width:767px) {
    .pg1-slider-1 .swiper-button-next{
      right: 40% !important;
      top: 88% !important;
    }
    .pg1-slider-1 .swiper-button-prev{
      left: 40% !important;
      top: 88% !important;
    }
  }
  @media (max-width:449px) {
    .pg1-slider-1 .swiper-button-next{
      right: 38% !important;
    }
    .pg1-slider-1 .swiper-button-prev{
      left: 38% !important;
    }
  }
  @media (max-width:380px) {
    .pg1-slider-1 .swiper-button-next{
      right: 36% !important;
    }
    .pg1-slider-1 .swiper-button-prev{
      left: 36% !important;
    }
  }
  .pg1-slider-1 .swiper-button-prev:after, .pg1-slider-1 .swiper-button-next:after {
    font-size: 18px !important;
    color: black !important;
    font-weight: bolder;
   }
   .pg1-section-1-text{
    font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  letter-spacing: 0.4em;
  color: #002ED4;
   }
  .pg1-section1-heading{
    font-family: Montserrat;
  font-size: 40px;
  font-weight: bold;
  line-height: 48.76px;
  color: #000000;
  -webkit-text-stroke: 1px black;
  }
  .pg1-section1-text2{
    font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  color: #000000;
  padding-left: 5rem !important;
  padding-right: 5rem !important;
  }
  .pg1-section1-text3{
    font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  color: #000000;
  }
  @media (max-width:991px) {
    .pg1-section1-text2{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
  @media (max-width:767px) {
    .pg1-section1-text2{
      padding-left: 0rem !important;
      padding-right: 0rem !important;
    }
  }
  .pg1-card h4{
    font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: 31.47px;
  }
  .pg1-card p{
    font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.13px;
  }
  @media screen and (max-width:767px) {
    .hero-section-heading {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 700;
        line-height: 40.51px;
        color: white;
    }
    .hero-section-text {
      font-family: Montserrat;
      font-size: 17px;
      font-weight: 400;
      line-height: 21.38px;
      color: white;
    }
    .pg1-section1-heading {
      font-family: Montserrat;
      font-size: 27px;
      font-weight: 800;
      line-height: 31.76px;
      color: #000000;
  }
  
  .pg1-section1-text2 {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 24.38px;
      color: #000000;
  }
    }
    @media screen and (max-width:767px) {
      .pg1-slider-1 .swiper {
        height: 360px !important;
    }
    }
    .swiper-slide{
      cursor: pointer !important;
    }
    .pg1-slider-1 .swiper-slide{
      height: 260px !important;
    }
    @media  (max-width:1199px) {
      .pg1-slider-1 .swiper-slide {
        height: 285px !important;
    }
    }
    .pg1-slider-1 img{
      height: 71px !important;
    }
    @media (max-width: 767px) {
      .pg1-slider-1 .swiper-slide {
          height: 272px !important;
      }
    }
    @media screen and (max-width:767px) {
      .pg1-card img{
        margin-top: 20px !important;
      } 
    }
    .pg1-s3-bg{
      background-image: url('/public/assets/pg1-s3-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #F8F8F8;
    }
    .pg1-s3-margin{
      margin-top: 20%;
    }
    .s3-custom-bg-pg1{
      background-color: #B7FFAB;
  padding: 14px 30px 14px 30px;
  margin-left: 1px;
    }
    .s3-custom-bg2-pg1{
      background-color: #BED0FF;
  padding: 14px 30px 14px 30px;
  margin-left: 1px;
    }
    .s3-custom-bg-pg1 h4{
      font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
    }
    .s3-custom-bg-pg1 p{
      font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
    }
    .s3-custom-bg2-pg1 h4{
      font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
    }
    .s3-custom-bg2-pg1 p{
      font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
    }
    .pg1-s3-btn{
  padding: 10px 40px 10px 40px;
  border-radius: 40px;
  color: white;
  background-color: #61DD00;
  border: none;
  font-family: Montserrat;
  font-weight: 800;
    }
    .s3-position-img{
      position: relative;
    }
    .s3-position-img1{
      position: absolute;
      bottom: 0%;
      right: 33%;
    }
    @media (max-width:991px) {
        .s3-pg1-hide{
            display: none;
        }   
    }
    @media (max-width:767px) {
        .s3-custom-bg-pg1 {
            background-color: #B7FFAB;
            padding: 14px 5px 14px 5px;
        }
    }
    @media (max-width:767px) {
        .s3-custom-bg2-pg1 {
            padding: 14px 5px 14px 5px;
        }
    }
    .pg1-s4-bg{
      background-image: url('/public/assets/blue-smooth-wall-textured-background1.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    
    .pg1-section-4-text{
      font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    letter-spacing: 0.4em;
    color: white;
     }
     .pg1-section4-heading{
      font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    line-height: 48.76px;
    color: white;
    -webkit-text-stroke: 1px white;
    }
    @media screen and (max-width:767px) {

      .pg1-section4-heading {
        font-size: 27px;
    }
  }
  .pg1-s4-text1{
    font-family: Poppins;
font-size: 32px;
font-weight: 500;
line-height: 37.46px;
letter-spacing: -0.6610773205757141px;
color: #FFFFFF;
  }
  .s4-custom-position{
    position: relative;
  }
  .s4-custom-position1{
    position: absolute;
    top: 0%;
    right: 0%;
    top: -23%;
    right: -20%;
  }
  @media (max-width:1199px) {
    .s4-custom-position1{
      right: -24%;
    }
  }
  @media (max-width:991px) {
    .s4-custom-position1{
      right: -17%;
    }
  }
  .img-s4-custom{
    width: 140px;
    height: 140px;
  }
  @media screen and (max-width:991px) {
    .s4-img-hide{
      display: none;
    }
  }
  @media (max-width:767px) {
    .img-s4-custom {
      width: 110px;
      height: 110px;
  }
  .pg1-s4-text1 {
    font-size: 21px;
    line-height: 20.46px;
}
.s4-custom-position1 {
  top: -35%;
  right: -20%;
}
  }
  @media (max-width:400px) {
    .img-s4-custom {
      width: 95px;
      height: 95px;
  }
  }
  @media (max-width:550px) {
    .s4-custom-position1 {
      width: 80px;
      right: -17%;
    }
  }
  @media (max-width:485px) {
    .s4-custom-position1 {
      right: -20%;
    }
  }
  @media (max-width:425px) {
    .s4-custom-position1 {
      right: -22%;
    }
  }
  @media (max-width:399px) {
    .s4-custom-position1 {
      right: -24%;
    }
  }
  .pg1-card2{
padding: 25px 40px 25px 40px;
border-radius: 10px;
background-color: #FFFFFF;
box-shadow: 6px 6px 20px 0px #0000001A;
  }
  .pg1-s5-bg{
    background-color: #F8F8F8;

  }
  .card2-heading{
    font-family: Montserrat;
font-size: 18px;
font-weight: 600;
line-height: 28.8px;
  }
  .pg1-slider-2 .swiper{
    height: 540px;
   }
   .pg1-slider-2 .swiper-wrapper{
    height: auto;
   }
  .card2-text{
    font-family: Montserrat;
font-size: 16px;
font-weight: 500;
line-height: 28.8px;
  }
  .pg1-slider-2 .swiper-button-prev{
    top: 85% !important;
    left: 45% !important;
    background-color: #FFFFFF !important;
    border-radius: 100% !important;
    opacity: 1 !important;
    height: 28px !important ;
    padding: 20px !important;
    }
    .pg1-slider-2 .swiper-button-next{
      top: 85% !important;
      right: 45% !important;
      background-color: #FFFFFF !important;
    border-radius: 100% !important;
    opacity: 1 !important;
    height: 28px !important ;
    padding: 20px !important;
    }
    @media (max-width:1199px) {
      .pg1-slider-2 .swiper-button-next{
        top: 95% !important;
      }
      .pg1-slider-2 .swiper-button-prev{
        top: 95% !important;
      }
    }
    @media (max-width:991px) {
      .pg1-slider-2 .swiper-button-next{
        right: 43% !important;
        top: 85% !important;
      }
      .pg1-slider-2 .swiper-button-prev{
        left: 43% !important;
        top: 85% !important;
      }
    }
    @media (max-width:767px) {
      .pg1-slider-2 .swiper-button-next{
        right: 39% !important;
        top: 88% !important;
      }
      .pg1-slider-2 .swiper-button-prev{
        left: 39% !important;
        top: 88% !important;
      }
      .pg1-slider-2 .swiper {
        height: 440px;
    }
    }
    @media (max-width:449px) {
      .pg1-slider-2 .swiper-button-next{
        right: 37% !important;
      }
      .pg1-slider-2 .swiper-button-prev{
        left: 37% !important;
      }
    }
    @media (max-width:380px) {
      .pg1-slider-2 .swiper-button-next{
        right: 36% !important;
      }
      .pg1-slider-2 .swiper-button-prev{
        left: 36% !important;
      }
    }
    .pg1-slider-2 .swiper-button-prev:after, .pg1-slider-2 .swiper-button-next:after {
      font-size: 18px !important;
      color: black !important;
      font-weight: bolder;
     }
@media (max-width:550px) {
  .pg1-slider-2 .swiper {
    height: 440px;
}
}
@media (max-width: 476px) {
  .pg1-slider-2 .swiper {
      height: 480px;
  }
}
@media (max-width: 436px) {
  .pg1-slider-2 .swiper {
      height: 520px;
  }
}
@media (max-width: 38px) {
  .pg1-slider-2 .swiper {
      height: 540px;
  }
}
@media (max-width:767px) {
  .pg1-card2 {
    padding: 25px 25px 25px 25px;
  }
}
.pg1-s6-bg{
  background-image: url('/public/assets/s6-bg.png');
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  background-color: #F8F8F8;
  height: 500px;
}
.pg1-s7-bg{
  background: linear-gradient(220.74deg, #FFFFFF 6.8%, #B2B0FF 97.83%);
}
.inner-section-bg-s7{
  background: linear-gradient(104.7deg, #F9F9F9 2.08%, rgba(249, 249, 249, 0.4) 99.08%);
  box-shadow: 20px 20px 30px 0px #0000001A;
  border-radius: 20px;
}
.inner-section-bg-s7 h2{
  font-family: Montserrat;
font-size: 32px;
font-weight: 800;
line-height: 49.57px;
}
.inner-section-bg-s7 p{
  font-family: Montserrat;
  font-size: 22px;
  line-height: 37.18px;
  text-align: left;  
}
.inner-section-bg-s7 h5{
  font-family: Montserrat;
font-size: 18px;
font-weight: 700;
line-height: 30.98px;
}
.s7-position-1{
  position: relative;
}
.s7-position-2{
  position: absolute;
  height: 400px;
  bottom: 0%;
  right: 3%;
}
.s7-position-3{
  position: relative;
}
.s7-position-4{
  position: relative;
  top: -47%;
}
@media (max-width:1199px) {
  .s7-position-4{
    top: -51%;
  }
}
@media (max-width:767px) {
  .s7-position-4{
    top: -25%;
  }
}
@media (max-width:767px) {
  .s7-position-2{
    position: unset;
  }
}
@media (max-width:767px) {
  .padding-custom-s7{
    padding-right: 15px !important;
  }
}
@media (max-width:767px) {
  .s7-position-1{
display: none;
  } 
  .inner-section-bg-s7 h2{
    font-size: 24px !important;
    line-height: 34px;
  }
  .inner-section-bg-s7 p {
    font-size: 18px;
    line-height: 27.18px;
}
.inner-section-bg-s7 h5 {
  font-size: 16px;
  line-height: 27.98px;
}
}
@media (min-width:768px) {
  .hide-img-s7{
display: none;
  } 
}
@media (max-width: 767px) {
  .s7-position-4 {
      top: -19%;
  }
}
@media (max-width: 549px) {
  .s7-position-4 {
      top: -22%;
  }
}
@media (max-width: 449px) {
  .s7-position-4 {
      top: -25%;
  }
}
@media (max-width:403px) {
  .inner-section-bg-s7 h5 {
margin-left: 0.5rem !important;
  }
}
@media (max-width:387px) {
  .inner-section-bg-s7 h5 {
font-size: 14px;
  }
}

@media (max-width: 402px) {
  .s7-position-4 {
      top: -29%;
  }
}
@media (max-width: 362px) {
  .s7-position-4 {
      top: -33%;
  }
} 
@media (max-width:1900px) {
  .pg1-s8{
    margin-top: -14%;
  } 
}
@media (max-width:1800px) {
  .pg1-s8{
    margin-top: -15%;
  } 
}
@media (max-width:1700px) {
  .pg1-s8{
    margin-top: -16%;
  } 
}
@media (max-width:1600px) {
  .pg1-s8{
    margin-top: -17%;
  } 
}
@media (max-width:1350px) {
  .pg1-s8{
    margin-top: -19%;
  } 
}
@media (max-width: 1199px) {
  .pg1-s8 {
    margin-top: -25%;
  }
}

@media (max-width: 1169px) {
  .pg1-s8 {
    margin-top: -26%;
  }
}

@media (max-width: 1139px) {
  .pg1-s8 {
    margin-top: -27%;
  }
}

@media (max-width: 1109px) {
  .pg1-s8 {
    margin-top: -28%;
  }
}

@media (max-width: 1079px) {
  .pg1-s8 {
    margin-top: -29%;
  }
}

@media (max-width: 1049px) {
  .pg1-s8 {
    margin-top: -30%;
  }
}

@media (max-width: 1019px) {
  .pg1-s8 {
    margin-top: -31%;
  }
}

@media (max-width: 989px) {
  .pg1-s8 {
    margin-top: -32%;
  }
}

@media (max-width: 959px) {
  .pg1-s8 {
    margin-top: -38%;
  }
}

@media (max-width: 929px) {
  .pg1-s8 {
    margin-top: -39%;
  }
}

@media (max-width: 899px) {
  .pg1-s8 {
    margin-top: -40%;
  }
}

@media (max-width: 869px) {
  .pg1-s8 {
    margin-top: -41%;
  }
}

@media (max-width: 839px) {
  .pg1-s8 {
    margin-top: -42%;
  }
}

@media (max-width: 809px) {
  .pg1-s8 {
    margin-top: -43%;
  }
}

@media (max-width: 779px) {
  .pg1-s8 {
    margin-top: -44%;
  }
}

@media (max-width: 767px) {
  .pg1-s8 {
    margin-top: -28%;
  }
}
@media (max-width:737px) {
  .pg1-s8 {
    margin-top: -29%;
  }
}
@media (max-width:707px) {
  .pg1-s8 {
    margin-top: -30%;
  }
}
@media (max-width:677px) {
  .pg1-s8 {
    margin-top: -31%;
  }
}
@media (max-width:637px) {
  .pg1-s8 {
    margin-top: -32%;
  }
}
@media (max-width:607px) {
  .pg1-s8 {
    margin-top: -33%;
  }
}
@media (max-width:577px) {
  .pg1-s8 {
    margin-top: -34%;
  }
}
@media (max-width:549px) {
  .pg1-s8 {
    margin-top: -43%;
  }
}
@media (max-width:517px) {
  .pg1-s8 {
    margin-top: -45%;
  }
}
@media (max-width:487px) {
  .pg1-s8 {
    margin-top: -47%;
  }
}
@media (max-width:457px) {
  .pg1-s8 {
    margin-top: -48%;
  }
}
@media (max-width:449px) {
  .pg1-s8 {
    margin-top: -55%;
  }
}
@media (max-width:419px) {
  .pg1-s8 {
    margin-top: -58%;
  }
}
@media (max-width:402px) {
  .pg1-s8 {
    margin-top: -65%;
  }
}
@media (max-width:390px) {
  .pg1-s8 {
    margin-top: -68%;
  }
}
@media (max-width:380px) {
  .pg1-s8 {
    margin-top: -72%;
  }
}
@media (max-width:365px) {
  .pg1-s8 {
    margin-top: -78%;
  }
}
.s8-heading{
  color: #444444 !important;
}
.s8-text{
  color: #444444 !important;
}
.s8-button-1{
  background-color: #444444;
  color: white;
padding: 15px;
border-radius: 5px;
border: 2px solid #444444;
}
.s8-button-2{
  background-color: #444444;
  color: white;
padding: 15px;
border-radius: 5px;
border: 2px solid #444444;
background-color: transparent;
color:#444444;
}
.card{
  background-color: transparent;
  border: none;
}
.card h5{
  font-family: Montserrat;
font-size: 26px;
font-weight: 700;
line-height: 31.69px;
}
.card p{
  font-family: Montserrat;
font-size: 20px;
font-weight: 500;
line-height: 24.38px;
}
@media (max-width:767px) {
  .card h5{
    font-size: 21px;
  }
  .card p{
    font-size: 18px;
  }
}
.pg1-s8 .span1{
font-size: 16px;
font-weight: 600;
border-radius: 100%;
  background-color: #002DCC;
  color: white;
  border: 2px solid white;
  padding: 12px 20px;
}
.pg1-s8 .span2{
font-size: 16px;
font-weight: 600;
border-radius: 100%;
  background-color: transparent;
  color: #002DCC;
  border: 2px solid #002DCC;
  padding: 12px 20px;
}
.swiper-3 .swiper-slide{
  width: auto !important;
}
.cardbtn{
  display: none;
}
.card:hover{
  cursor: pointer;
  .cardbtn{
    display: block;
  }
}
.cardbtn1{
  display: none;
}
.card:hover{
  cursor: pointer;
  .cardbtn1{
    display: block;
  }
}
.cardbtn2{
  display: none;
}
.card:hover{
  cursor: pointer;
  .cardbtn2{
    display: block;
  }
}
.cardbtn3{
  display: none;
}
.card:hover{
  cursor: pointer;
  .cardbtn3{
    display: block;
  }
}
.cardbtn4{
  display: none;
}
.card:hover{
  cursor: pointer;
  .cardbtn4{
    display: block;
  }
}
.cardbtn5{
  display: none;
}
.card:hover{
  cursor: pointer;
  .cardbtn5{
    display: block;
  }
}
.pagination span{
  cursor: pointer;
}
.navbar{
  box-shadow: none !important;
}
.navbar-logo1{
  font-weight: bold;
}
.navbar-brand{
  display: block !important;
  color: white !important;
  font-family: Montserrat;
font-size: 30px;
font-weight: 500;
-webkit-text-stroke: 1px white;
}
@media (max-width:767px) {
  .navbar-brand{
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 500;
    line-height: 32.45px;
    text-align: left;

  }
}
.navbar-nav li a{
  color: white !important;
  font-family: Montserrat;
  font-weight: 600;
}
.btn-outline-success{
padding: 12px 40px 12px 40px !important;
border-radius: 40px !important;
background-color: #00E000 !important;
color: white !important;
border: none !important;
font-family: Montserrat;
font-size: 16px;
font-weight: 800;
}
.navbar-toggler{
  border: 1px solid #00E000 !important;
}
.navbar-toggler:focus{
  color: transparent !important;
}
@media (max-width:767px) {
  .hero-section-swiper {
    padding: 12px 20px 10px 20px;
    font-size: 16px;
}
}
.nav-link {
  position: relative;
  cursor: pointer !important;
}

.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 3px;
  border-radius: 5px;
  background-color: #70FF00;
}
